@import "../../styles/variables";

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding-bottom: 100px;
  position: relative;
}
.icon {
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
}

.name {
  display: flex;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 138%;
  /* identical to box height, or 28px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* FFF */
  margin-bottom: 30px;
  color: map-get($colors, c5);
}

.bottomDescription {
  margin-left: 16px;
  margin-right: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  width: 100%;
  line-height: 138%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* or 55px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* FFF */

  color: map-get($colors, c5);
  margin-top: 60px;
}

.title {
  margin-left: 16px;
  margin-right: 16px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 38px;
  width: 100%;
  line-height: 138%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* or 55px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* FFF */

  color: map-get($colors, c5);
  margin-bottom: 47px;
  @media (max-width: 360px) {
    margin-bottom: 20px;
    font-size: 26px;
  }
}

.description {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 138%;
  /* or 28px */

  text-align: center;
  letter-spacing: 0.0075em;
  margin-left: 16px;
  margin-right: 16px;

  /* FFF */

  color: map-get($colors, c5);
  margin-bottom: 29px;
}

.subDescription {
  margin-left: 16px;
  margin-right: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 138%;
  /* or 22px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* FFF */

  color: map-get($colors, c5);
}
.count {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 70px;
  line-height: 138%;
  /* identical to box height, or 97px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* FFF */

  color: map-get($colors, c5);
}

.customFooter {
  background: #f6f7fa;
  box-shadow: 0px -10px 30px map-get($colors, boxShadow);
  border-radius: 40px 40px 0px 0px;
  width: 100%;
  height: auto;
  min-height: 100px;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  justify-self: flex-end;
  align-self: flex-end;
  position: fixed;
  padding: 5px 25px 25px 25px;
}

.customFooterTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 138%;
  /* identical to box height, or 25px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* 474A59 */

  color: #474a59;
  margin-bottom: 35px;
}

.customFooterDesc {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 138%;
  /* or 22px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* 474A59 */

  color: #474a59;
  margin-bottom: 17px;
}
.customFooterSubDesc {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* 474A59 */

  color: #474a59;
  margin-bottom: 25px;
}
.customFooterButton {
  background: #40507e;
  border-radius: 22px;
  width: 231px;
  height: 44px;
  color: white;
  text-decoration: none;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 138%;
  outline: none;
  border: none;

  &:hover {
    border: none;
    outline: none;
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
    border: none;
    outline: none;
  }
}

.countDescription {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.0075em;

  /* FFF */
  color: map-get($colors, c5);
}
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background: map-get($colors, c5);
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 138%;
    /* identical to box height, or 25px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* 40507E */

    color: #40507e;
  }
}

.popUp {
  background: map-get($colors, c5);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  width: 90%;
  height: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding: 21px 12px 21px 12px;
}
.popUpTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* 474A59 */

  color: #474a59;
}
.popUpDesc {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* 474A59 */

  color: #474a59;
}
.infoContainer {
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 30px auto;
  position: relative;
  // bottom: 120px;
  left: 0px;
}
.infoWrapper {
  display: flex;
}
.infoTitle {
  justify-content: flex-end;
  display: flex;
  flex: 1;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  text-align: right;
  letter-spacing: 0.0075em;

  /* FFF */

  color: map-get($colors, c5);
}

.text {
  display: flex;
  flex: 1;
  margin-left: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* FFF */

  color: map-get($colors, c5);
}

.timeCountContainer {
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: rgba(255, 255, 255, 0.2);
  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 138%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.0075em;
    color: white;
    margin: 5px;
  }
}
