.buttonContainer {
  position: fixed;
  width: 100%;
  padding-left: 256px;
  height: 66px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -1px 1px rgba(0, 0, 0, 0.25);
  display: flex;
}
.lightButton {
  width: 121px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #7185be;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  text-align: center;
  letter-spacing: 0.0075em;
  color: #7185be;
  margin-right: 20px;
  &:hover {
    background: #7185be;
    color: #ffffff;
  }
  &:focus {
    outline: none;
  }
}

.darkButton {
  width: 121px;
  height: 40px;
  left: 1197px;
  background: #40507e;
  border: 1px solid #40507e;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  &:hover {
    background: #ffffff;
    color: #40507e;
  }
  &:focus {
    outline: none;
  }
}
.flex {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.mainContainer {
  padding-bottom: 100px;
}
.genericBox {
  background: rgba(165, 180, 201, 0.2);
  border-radius: 6px;
  display: flex;
  flex: 1;
  height: 127px;
  width: 100%;
  margin-right: 17px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.genericWrapper {
  display: flex;
  flex-direction: row;
}
.divider {
  width: 100%;
  height: 1px;
  margin-top: 20px;
  border: 0.5px solid rgba(165, 180, 201, 0.2);
}
.date {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 138%;
  /* identical to box height, or 25px */

  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}

.nameWrapper {
  display: flex;
  flex-direction: column;
}

.text {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* 7185BE */

  color: #7185be;
  margin-top: 15px;
}
.lastName {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;
  color: #40507e;
}

.desc {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* 7185BE */

  color: #40507e;
}
.hseChecks {
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(233, 235, 236, 0.5);
}
.sectionName {
  margin-top: 10px;
  margin-bottom: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  margin-left: auto;
  margin-right: auto;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}

.attempts {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 138%;
  /* identical to box height, or 17px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* 7185BE */

  color: #7185be;
}
.dangerZoneText {
  /* Caption OpenSans */

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 138%;
  /* or 17px */

  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}

.loadingContainer {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.darkButton {
  width: 121px;
  height: 40px;
  left: 1197px;
  background: #40507e;
  border: 1px solid #40507e;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  &:hover {
    background: #ffffff;
    color: #40507e;
  }
  &:focus {
    outline: none;
  }
}

.cardIcon {
  width: 24px;
}

.contractorCardWrapper thead,
.contractorCardWrapper table,
.contractorCardWrapper .container {
  background: none;
  border: none;
}

.contractorCardWrapper thead {
  border-bottom: 1px solid #e9ebec;
}

.contractorCardWrapper td:first-child div {
  margin-top: 0 !important;
  margin-left: 10px;
}
.excludedWarning {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-top: 14px;
  img {
    width: 30px;
    height: 30px;
  }
  span {
    margin-left: 15px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    letter-spacing: 0.0075em;

    /* E26C82 */

    color: #e26c82;
  }
}
