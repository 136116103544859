@import "../../../../../styles/variables";

.StepContainer {
  width: 95%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
}
.ContentWrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  p {
    display: block;
    margin-block-start: 0em !important;
    margin-block-end: 0em !important;
    margin-inline-start: 0px !important;
    margin-inline-end: 0px !important;
    margin-top: unset !important;
    margin-bottom: unset !important;
  }
}
.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height */
  margin-top: 18px;
  text-align: center;
  width: 70%;
  margin: 20px auto;
  color: map-get($colors, c5);
}
.playArea {
  width: 100%;
  height: calc(100vh - 300px);
  overflow: hidden;
  margin: 20 auto;
  display: flex;
  flex-direction: row;
}

.cardContainer {
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.cardHeader {
  display: flex;
  width: 90%;
  height: 51px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  span {
    text-align: center;
    display: flex;
    color: map-get($colors, c5);
    font-family: Open Sans;
    font-weight: 600;
    font-size: 20px;
  }
}

.cardContent {
  display: flex;
  width: 90%;
  font-size: 15px;
  margin: 0px auto;
  text-align: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
