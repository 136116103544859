.headerContainer {
  background: white;
  box-shadow: none;
  position: relative;
  min-height: 80px;
  border-bottom: 1px solid #dddddd;
}

.headerLogo {
  height: 65px;
  width: 86px;
  min-height: 65px;
}

.DrawerContainer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #73818f;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 999;
}

.open {
  background: white;
  border: none;
  box-shadow: none;
  position: relative;
  animation: slideLeft 0.5s;
  animation-direction: alternate;
  right: 50%;
}

@keyframes slideLeft {
  0% {
    right: 0px;
    top: 0px;
  }
  100% {
    right: 50%;
    top: 0px;
  }
}
