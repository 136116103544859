.Title {
  font-family: Open Sans;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 24px;
  text-align: center;
  color: #000000;
}

.Content {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 30px;
  color: #000000;
}
.List {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}
.Item {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 20px;
  color: #000000;
}
.Image {
  width: 90%;
  height: auto;
}
.Link {
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
}
.SiteRuleContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}
.ImageContainer {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: row;
}
.Image {
  display: flex;
  flex: 1;
  background-size: cover;
  background-position: center center;
}
