.FooterContainer {
  width: 100%;
  height: 60px;
  background: #ffffff;
  background-color: #ffffff;
  box-shadow: 0px -16px 40px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

@media (min-width: 1024px) {
  .FooterContainer {
    width: 687px;
    height: 80px;
    background: #ffffff;
    background-color: #ffffff;
    box-shadow: none;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .DesktopFooter {
    width: 100vw;
    height: 80px;
    background-color: #ffffff;
    box-shadow: 0px -16px 40px rgba(0, 0, 0, 0.1);
    position: fixed;
    z-index: 3;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
