.ItemCard {
  width: 55vw;
  height: 20vh;
  background: #ffffff;
  box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.15);
  border-radius: 12.6984px;
  margin: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.ItemText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 25.6px;
  line-height: 35px;
  /* identical to box height, or 84% */
  margin: 5px;
  text-align: center;
  letter-spacing: 0.0426667px;

  color: #353535;
}
.Checkbox {
  width: 50px;
  height: 50px;
  background: #ffffff;
  border-radius: 22.5px;
  border: 2px solid #ffffff;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.0615986),
    0px 0px 20px rgba(82, 87, 84, 0.1);
}
.ItemCheckboxWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.CorrectIconImage {
  width: 100%;
  height: 100%;
}

@media (max-width: 760px) {
  .ItemCard {
    width: 55vw;
    height: 25vh;
    background: #ffffff;
    box-shadow: 0px 5px 18px rgba(0, 0, 0, 0.15);
    border-radius: 12.6984px;
    margin: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ItemText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 3vh;
    line-height: unset;
    /* identical to box height, or 84% */
    margin: 5px;
    text-align: center;
    letter-spacing: 0.0426667px;

    color: #353535;
  }
}
