@import "../../styles/variables";

.container {
  padding: 20px;
}

.FormWrapper {
  width: 100%;
}

.SelectContainer {
  width: 100%;
  margin-bottom: 51px;
  position: relative;
}

.BlockLabel {
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-size: 14px;
  line-height: 55px;
  color: map-get($colors, c1);
  margin-left: 20px;
}

.title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 138%;
  /* or 30px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_0F1619 */
  margin-bottom: 60px;
  color: #0f1619;
}
.personaItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
}
.checkbox {
  background: #ffffff;
  /* KS_E9EBEC */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9ebec;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  width: 40px;
  height: 40px;
  align-items: center;
  border-radius: 40px;
  margin: 18px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.personaTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 138%;
  /* identical to box height, or 25px */
  letter-spacing: 0.0075em;

  /* KS_0F1619 */

  color: #0f1619;
}
