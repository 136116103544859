.menuContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: relative;
  margin-bottom: 20px;
}
.menuWrapper {
  width: 80%;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
}
.divider {
  background-color: #f6f7fa;
  height: 1px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  width: 100%;
  border-radius: 0px;
  position: absolute;
  bottom: -7px;
  left: 0;
}
.menuItem {
  margin: 2px;
  position: relative;
  height: 20px;
  width: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  color: #7185be;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    width: 102px;
    height: 4px;
    bottom: -10px;
    left: 0px;
    background: #40507e;
    border-radius: 6px 6px 0px 0px;
    z-index: 3;
  }
}
.inActiveMenuItem {
  cursor: pointer;
  margin: 2px;
  position: relative;
  height: 20px;
  width: 102px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  color: #7185be;
  opacity: 0.5;
}

@media print
{
   .menuContainer{display: none;}

}



