.cardContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
  background: #ffffff;
  border: 1px solid #f8f8f8;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.titleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  background-color: rgba(113, 133, 190, 0.1);
}
.title {
  display: flex;
  margin-left: 10px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}
.body {
  min-height: 170px;
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
}

.buttonWrapper {
  position: absolute;
  right: 20px;
}

.buttonWrapper button {
  border-radius: 0;
  background: #7185be;
  color: #fff;
  width: 120px !important;
  height: 40px;
}

.buttonWrapper button svg,
.buttonWrapper button path {
  fill: #fff;
}

.pillsWrapper {
  margin-left: 15px;
}

.pills {
  padding: 5px 12px;
  text-align: center;
  color: #fff;
  background-color: #ccc;
  border-radius: 10px;
  margin-left: 5px;
}

.redBorder {
  border: 2px solid #e26c82;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}
