.Rectangle {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 46px;
  border-radius: 22.5px;
  box-shadow: 0px 15px 40px rgba(117, 117, 170, 0.0971646);
  cursor: pointer;
}
.Circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 22.5px;
  background: linear-gradient(257.04deg, #20a8d8 -16.45%, #20a8d8 74.14%);
  box-shadow: 0px 4px 8px rgba(27, 28, 88, 0.202852),
    0px 20px 40px rgba(62, 65, 247, 0.128742);
}
.LineContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  margin: 10px;
}
.Line1 {
  width: 15px;
  height: 2px;
  background: #fff;
  border-radius: 1.1px;
  margin-bottom: 3.42px;
}

.Line2 {
  width: 7.5px;
  height: 2px;
  background: #fff;
  border-radius: 1.1px;
  margin-bottom: 3.42px;
}

.Line3 {
  width: 15px;
  height: 2px;
  background: #fff;
  border-radius: 1.1px;
}
