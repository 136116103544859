@import "../../../../styles/variables";

.Container {
  display: flex;
  position: relative;
  width: 100%;
  right: 0;
  height: 100%;
  width: 100vw;
  background-color: map-get($colors, c15);
  align-items: center;
  flex-direction: column;
}

.AssesmentCounter {
  margin-top: 36px;
  margin-bottom: 14px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  height: 26px;
  /* identical to box height, or 144% */

  text-align: center;

  color: #595b60;
}
.AssesmentHeaderText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  /* identical to box height, or 168% */
  text-align: center;

  color: map-get($colors, c5);
}
.CloseIcon {
  position: absolute;
  top: 44px;
  left: 32px;
  width: 25px;
  height: 25px;
}
.KnowledgeCheckContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 264px;
  height: 40px;
  min-height: 40px;
  overflow: hidden;
  background-color: map-get($colors, c14);
  border-radius: 11px;
  margin-bottom: 10px;
}
.CompletionWrapper {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.questionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 760px) {
  .CloseIcon {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 25px;
    height: 25px;
  }
}

@media (min-width: 1024px) {
  .Container {
    display: flex;
    position: relative;
    width: 100%;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-width: 768px;
    background-color: map-get($colors, c15);
    align-items: center;
    flex-direction: column;
  }
  .questionWrapper {
    width: 750px;
    display: flex;
    flex-direction: column;
  }
  .CompletionWrapper {
    width: 750px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
