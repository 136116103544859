@import "../../../../../styles/variables";

.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  margin-top: 18px;
  text-align: start;
  margin-bottom: 17px;
  color: map-get($colors, c5);
}
.contentWrapper {
  display: flex;
  flex-direction: column;
}
.contentTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  margin-bottom: 17px;

  color: map-get($colors, c5);
}
.contentText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  margin-bottom: 17px;
  display: flex;
  color: map-get($colors, c5);
}

.StepContainer {
  width: 95%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
}
.ImageContainer {
  display: flex;
  flex-grow: row;
  width: 100%;
  height: 120px;
  justify-content: center;
  align-items: center;
  margin: 43px auto;
}
.ImageWrapper {
  flex: 1 1 0%;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  border-radius: 20px;
  background-size: cover;
  background-position: center center;
  margin: 15px;
}
.Image {
}

@media (min-width: 700px) {
  .ImageContainer {
    display: flex;
    flex-grow: row;
    width: 100%;
    height: 240px;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
  }
}

@media (min-width: 1024px) {
  .ImageContainer {
    display: flex;
    flex-grow: row;
    width: 100%;
    height: 200px;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
  }
}
