.OverviewContainer {
  display: flex;
  flex-direction: row;
  height: 200px;
  width: 100%;
}

.OverviewCard {
  display: flex;
  flex: 1;
  margin: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
}

.cardIcon {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.cardData {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: -5px;
  padding-left: 31px;
  font-family: Open Sans Condensed;
}
.cardDesc {
  margin-bottom: 10px;
  font-family: Open Sans;
  font-weight: 300;
  color: "#D8D8D8";
  padding-left: 31px;
}
.midTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 138%;
  /* identical to box height, or 28px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: #28353c;
  margin-left: 15px;
}
.cardProgressContainer {
  width: 90%;
  margin: 30px auto 0px auto;
  height: 8px;
  background-color: #f0f3f5;
  border-radius: 20px;
  position: relative;
}

.cardProgress {
  width: 50%;
  height: 100%;
  background-color: #1d1d1d;
  border-radius: 20px;
}

@media (max-width: 1024px) {
  .OverviewContainer {
    display: flex;
    flex-direction: column;
    height: 800px;
  }
}
