@import "../../styles/variables";

.Container {
  display: flex;
  flex-direction: column;
  margin: 0 auto 50px auto;
}
.TextContainer {
  display: flex;
  flex-direction: column;
  margin: 0px auto 20px auto;
  width: 90%;
}
.Heading {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  letter-spacing: 4.17232e-9px;
  margin-bottom: 24px;
  color: map-get($colors, c3);
}
.Description {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1.58946e-9px;

  color: map-get($colors, c8);
}
.Link {
  width: 100%;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.VRContainer {
  width: 100%;
  height: auto;
  margin-top: 20px;
  position: relative;
}
.Image {
  width: 100%;
  height: 100%;
}
.ButtonContainer {
  width: 100%;
  position: absolute;
  bottom: 20px;
  display: flex;
  justify-content: center;
}

@media (max-width: 760px) {
  .Heading {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    letter-spacing: unset;
    margin-bottom: 24px;
    color: map-get($colors, c3);
  }
  .Description {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: unset;

    color: map-get($colors, c8);
  }
}
