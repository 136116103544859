@import "../../../../../styles/variables";

.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */

  text-align: start;
  margin-bottom: 17px;
  color: map-get($colors, c5);
}
.content {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  /* or 125% */
  margin-bottom: 17px;
  display: flex;
  color: map-get($colors, c5);
}

.StepContainer {
  width: 95%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0px auto;
}
.StepImageContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 120px;
  justify-content: center;
  align-items: center;
  margin: 50px auto;
}
.StepImageWrapper {
  flex: 1 1 0%;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  border-radius: 20px;
  padding: 10px;
  background-size: cover;
  margin: 50px 15px;
  background-position: center center;
}
.StepImage {
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

@media (min-width: 1024px) {
  .StepImageContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 200px;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
  }
}
