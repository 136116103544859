.dashboardSection1 {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  flex: 3.5;
  margin: 10px;
}

.progressColumn {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.progressItem {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  height: 110px;
}
.progressIcon {
  margin: 5px;
  width: 65px;
  height: 65px;

  border-radius: 100px;
  border: 7px solid;
}
.progressWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  height: 100%;
}

.progressItemSection1 {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.progressItemSection2 {
  display: flex;
  flex: 3;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-left: 5px;

  p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    max-width: 80%;
    line-height: 138%;
    /* or 19px */

    letter-spacing: 0.0075em;

    /* 40507E */

    color: #40507e;
  }

  span {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400px;
    font-size: 12px;
    line-height: 16px;
    /* or 19px */

    letter-spacing: 0.0075em;

    /* 40507E */

    color: #40507e;
  }
}

.progressItemSection3 {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 22px;
  background: rgba(165, 180, 201, 0.2);
  border-radius: 0px 6px 6px 0px;
}
.passRateText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 138%;
  /* identical to box height, or 17px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}

.percentageText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 300;
  font-size: 30px;
  line-height: 138%;
  /* or 41px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}
