.container {
  padding-right: 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 100px;
}

.title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 138%;
  /* or 30px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_0F1619 */
  margin-left: 60px;
  margin-bottom: 80px;
  margin-top: 20px;
  color: #0f1619;
}
.personaItem {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 43px;
}
.checkbox {
  background: #ffffff;
  /* KS_E9EBEC */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9ebec;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  width: 40px;
  height: 40px;
  align-items: center;
  border-radius: 40px;
  margin: 18px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}
.personaTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 138%;
  /* identical to box height, or 25px */
  margin-bottom: 17px;
  letter-spacing: 0.0075em;

  /* KS_0F1619 */

  color: #0f1619;
}
.personaDesc {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  letter-spacing: 0.0075em;

  /* 444444 */

  color: #444444;
}
