@import "../../styles/variables";

.container {
  padding-right: 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 278px;
  min-height: calc(100vh - 100px);
  position: relative;
}

.title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 138%;
  /* or 30px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_0F1619 */
  margin-left: 60px;
  margin-bottom: 50px;
  margin-top: 20px;
  color: map-get($colors, c6);
}
.personaItem {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
}
.checkbox {
  background: map-get($colors, c5);
  /* KS_E9EBEC */
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e9ebec;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px map-get($colors, boxShadow);
  width: 40px;
  height: 40px;
  align-items: center;
  border-radius: 40px;
  margin: 18px;
  min-width: 40px;
}

.textWrapper {
  display: flex;
  flex-direction: column;
}
.personaTitle {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 138%;
  /* identical to box height, or 25px */
  letter-spacing: 0.0075em;

  /* KS_0F1619 */

  color: #0f1619;
}
.covidPopup {
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: 2;
  width: 100%;
  height: 250px;
  background-color: map-get($colors, c7);
  padding: 33px 15px 15px 15px;

  border-radius: 40px 40px 0px 0px;
  .covidTitle {
    align-self: center;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 138%;
    /* identical to box height, or 25px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* 4C4F5F */

    color: #4c4f5f;
  }

  .covidDescription {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    text-align: center;
    letter-spacing: 0.0075em;

    /* KS_28353C_Linear */

    background: linear-gradient(180deg, #3c4d56 0%, #28353c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .buttonWrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  button {
    margin-right: 10px;
    padding: 13px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 138%;
    /* or 19px */

    letter-spacing: 0.0075em;
    background: map-get($colors, c5);
    /* KS_28353C */
    display: flex;
    font-family: Open Sans;
    border: 1px solid map-get($colors, c1);
    border-radius: 6px;
    &:hover {
      text-decoration: none;
      background: map-get($colors, c5);
    }
    &:focus {
      text-decoration: none;
      background: map-get($colors, c5);
    }
  }
}

.warningIcon {
  margin: 70px auto;
}

.errorText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 138%;
  align-self: center;
  /* or 25px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* KS_28353C */

  color: map-get($colors, c1);
  margin-left: 16px;
  margin-right: 16px;
}
