@font-face {
  font-family: "Open Sans ExtraBold";
  src: url(./assets/fonts/OpenSans-ExtraBold.ttf) format("truetype");
}

$colors: (
  "c1": #28353c,
  "c2": #ffffff,
);

body {
  background-color: map-get($colors, c2) !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.modal-dialog {
  max-width: 95vw !important;
  max-height: 90vh !important;
  height: 100%;
}

.modal-content {
  height: 100%;
}

.tippy-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #7185be !important;
}
.supervisor-logout {
  z-index: 9999 !important;
}
.permission-nav {
  display: flex !important;
  justify-content: center;
  align-items: flex-end;
  z-index: 9999 !important;
}
.c-header.c-header-fixed {
  z-index: 2002 !important;
  display: flex !important;
  align-items: center;
  justify-content: flex-end;
}

@media print {
  .c-body {
    background: #fff !important;
    margin-top: -60px;
  }

  .c-header,
  .supervisor-logout,
  .c-header-fixed,
  .permission-nav {
    display: none !important;
    opacity: 0 !important;
    background-color: #fff !important;
  }
}
