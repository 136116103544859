.contractorLayout {
  background-color: white;
}
.wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  min-width: 0;
  min-height: 100vh;
}
.contractorBody {
  height: calc(100vh - 78px);
  width: 100%;
  margin: none;
  position: relative;
  filter: none !important;
}

.blur {
  height: calc(100vh - 78px);
  width: 100%;
  position: relative;
  animation: slideLeft 0.5s;
  animation-direction: alternate;
  right: 50%;
}

@keyframes slideLeft {
  0% {
    right: 0px;
    top: 0px;
  }
  100% {
    right: 50%;
    top: 0px;
  }
}

@media (min-width: 1024px) {
  .contractorLayout {
    background-color: white;
  }
  .contractorBody {
    height: calc(100vh - 78px);
    width: 750px;
    margin: 0 auto;
    position: unset;
  }
}
