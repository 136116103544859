.unchecked {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 2px solid #a5b4c9;
}
.checked {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  &:hover {
    background: white;
    background-color: white;
    svg {
      fill: none !important;
    }
    path {
      fill: #40507e !important;
    }
  }
}
