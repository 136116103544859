.container {
  display: flex;
  width: 100%;
  height: calc(100vh - 50px);
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box {
  display: flex;
  width: 463px;
  min-height: 463px;
  padding-bottom: 50px;
  height: auto;
  background: #ffffff;
  box-shadow: 5px 5px 40px 5px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  flex-direction: column;
}
.logo {
  width: 86px;
  height: 65px;
}

.button {
  margin-top: 55px;
  width: 100%;
  height: 48px;
  left: 547px;
  top: 553px;
  background: #40507e;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  text-decoration: none;
  outline: none;
  border: none;
  &:hover {
    text-decoration: none;
    background: #7185be;
    color: #ffffff;
    outline: none;
  }
  &:focus {
    text-decoration: none;
    outline: none;
  }
}

.title {
  display: flex;
  margin-top: 32px;
  margin-bottom: 18px;
  align-self: center;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 138%;
  /* identical to box height, or 28px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}
.desc {
  margin-bottom: 9px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  /* or 19px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}
.info {
  margin-bottom: 25px;

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 138%;
  /* identical to box height, or 17px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* 40507E */

  color: #40507e;
}

.succesIcon {
  display: flex;
  width: 53px;
  height: 39px;
  align-self: center;
  margin-top: 64px;
  margin-bottom: 34px;
}

.wrapper {
  width: 75%;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
}

.signButton {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  width: 100%;
  height: auto;
  cursor: pointer;
}

.brandWrapper {
  display: flex;
  flex-direction: row;
  margin-bottom: 54px;
  align-items: center;
  justify-content: flex-start;
  margin-top: 53px;
}
.brandLogo {
  width: 26px;
  height: 26px;
}
.brandText {
  width: 108px;
  height: 33px;
  margin-left: 12px;
}

.label {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  /* identical to box height, or 32px */

  /* KS_0F1619 */

  color: #0f1619;
}

.backArrowWrapper {
  display: flex;
  justify-content: center;
  align-self: flex-start;
  width: 44px;
  height: 44px;
  /* KS_FFF */
  border-radius: 44px;

  background: #ffffff;
  /* E26C82 */

  border: 1px solid #e26c82;
  box-sizing: border-box;
  align-items: center;
  z-index: 999;
}
.backArrow {
  width: 17px;
  height: 11px;
}

@media (max-width: 760px) {
  .box {
    display: flex;
    width: 95%;
    min-height: 463px;
    padding-bottom: 50px;
    height: auto;
    background: #ffffff;
    box-shadow: 5px 5px 40px 5px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    flex-direction: column;
    position: relative;
  }
}
.errorWrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
}
.errorMessage {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 138%;
  /* or 32px */
  width: 100%;
  margin-bottom: 20px;
  letter-spacing: 0.0075em;

  /* E26C82 */

  color: #e26c82;
}
