.QuestionTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* or 156% */
  margin-bottom: 40px;
  color: #000000;
  .Bold {
    font-weight: 700;
  }
}
.QuestionImage {
  border-radius: 20px;
  margin-bottom: 19px;
  display: block;
  min-width: 100%;
  max-height: 160px;
  width: 100%;
  height: auto;
}
.ChoiseContainer {
  height: 150px;
  width: 90%;
  background: #ffffff;
  border: 2px solid #2f353a;
  box-sizing: border-box;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  background-size: cover;
  background-position: center center;
  align-items: center;
  margin: "10px auto";
}
.ChoiseTextWrapper {
  margin: 5%;
  width: 80%;
  display: flex;
  justify-content: flex-start;
}

.ChoiseText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: #2f353a;
}
.SelectedCheckboxWrapper {
  margin: 2.5%;
  width: 10%;
  display: flex;
  position: absolute;
  top: 10px;
  right: 5px;
}
.ImageWrapper {
  flex: 1 1 0%;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  border-radius: 20px;
  background-size: cover;
  background-position: center center;
}

.successContainer {
  height: 50px;
  width: 100%;
  background-color: #dbf2e3;
  display: flex;
  align-items: center;
  span {
    color: #46b335;
    font-weight: bold;
    margin-left: 10px;
    font-size: 18px;
    font-family: Open Sans;
  }
}
.errorContainer {
  height: 50px;
  width: 100%;
  background-color: #f2dbdb;
  display: flex;
  align-items: center;
  span {
    color: #e30200;
    font-weight: bold;
    margin-left: 10px;
    font-size: 18px;
    font-family: Open Sans;
  }
}
.statusIcon {
  position: absolute;
  top: 35px;
  right: -40px;
}
