.Container {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 18px;
  margin-bottom: 18px;
}

.BlockLabel {
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #40507e;
  margin-right: 36px;
  width: 106px;
}

.BlockInput {
  width: 80%;

  background: #f8f8f8;
  height: 44px;
  display: block;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  padding: 0px 16px;
  font-family: Open Sans;
  font-size: 14px;
  color: #40507e;
  font-weight: bold;

  &:focus {
    outline: none;
  }
  &::placeholder {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.0075em;
    font-family: Open Sans;

    color: #a5b4c9;
    margin-top: 10px;
  }
}

.error {
  border: 1px solid #c40233 !important;
}
.errorMessage {
  position: absolute;
  bottom: -20px;
  margin-top: 10px;
  margin-left: 5px;
  font-family: Open Sans;
  color: #c40233;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 138%;
  /* or 17px */

  letter-spacing: 0.0075em;

  /* E26C82 */

  color: #e26c82;
}
.inputContainer {
  display: flex;
  position: relative;
  width: 100%;
}
