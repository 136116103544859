@import "../../styles/variables";

.Title {
  font-size: 5vh;
  color: map-get($colors, c3);
  font-family: Open Sans;
  font-weight: 700;
}
.Content {
  font-family: Open Sans;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  color: map-get($colors, c3);
}
.CheckboxWrapper {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 275px;
  max-width: 275px;
  width: 100%;
}

@media (min-width: 1024px) {
  .CheckboxWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 350px;
    max-width: 350px;
    width: 100%;
  }
}
@media (max-width: 375px) {
  .CheckboxWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 250px;
    max-width: 250px;
    width: 100%;
  }
}
@media (max-width: 320px) {
  .CheckboxWrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 200px;
    max-width: 200px;
    width: 100%;
  }
}
