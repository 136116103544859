.switch {
  background: #f8f8f8;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 22px;
  width: 84px;
  height: 38px;
  position: relative;
}
.active {
  position: absolute;
  right: 2px;
  bottom: 1px;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  background-color: #e26c82 !important;
}
.disabled {
  position: absolute;
  left: 2px;
  bottom: 1px;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  background: #a5b4c9;
}
.switchContainer {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 18px;
  margin-bottom: 18px;
}

.label {
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #40507e;
  margin-right: 36px;
}

.option1 {
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #a5b4c9;
  margin-right: 28px;
}
.activated {
  color: #40507e !important;
}
.option2 {
  display: block;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #a5b4c9;
  margin-left: 28px;
}
.activatedDanger {
  color: #6fcf97 !important;
}
.activeDanger {
  position: absolute;
  right: 2px;
  bottom: 1px;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  background: #6fcf97;
}
