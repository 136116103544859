@import "../../../styles/variables";

.InductionContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 130px;
  overflow: scroll;
}

.Heading {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: map-get($colors, c9);
  margin-bottom: 27px;
}
.SectionContainer {
  position: relative;
  border-radius: 20px 20px 0px 0px;
  margin: 0 auto;
  width: 100%;
  padding-bottom: 80px;
  height: 90vh;
}
.DragDropContainer {
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
  margin: 0 !important;
  width: 90%;
  height: calc(100vh - 200px);
}
.SectionWrapper {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}
.SectionTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  text-align: start;
  margin-top: 22px;
  margin-bottom: 18px;
  color: map-get($colors, c5);
}

.BackIconWrapper {
  display: flex;
  margin-right: 7px;
  margin-left: 20px;
}
.NextIconWrapper {
  display: flex;
  margin-left: 7px;
  margin-right: 20px;
}
.ProgressWrapper {
  display: flex;
  width: 90%;
  position: relative;
}
.ProgressBar {
  display: flex;
  position: relative;
  width: 90%;
  height: 8px;
  border-radius: 3px;
  background-color: map-get($colors, c10);
  margin: 0px auto;
  z-index: 0;
  opacity: 0.24;
}
.Progress {
  display: flex;
  position: absolute;
  height: 8px;
  border-radius: 3px;
  background-color: map-get($colors, c5);
  z-index: 1;
}

.FooterCompletion {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.FooterButtonWrapper {
  width: 100%;
  display: flex;
  flex: 1;
  margin: 10px;
  justify-content: center;
}

@media (max-width: 500px) {
  .Heading {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 38px;
    text-align: center;
    color: #191b26;
    margin-bottom: 27px;
    margin-left: 30px;
  }
}

@media (min-width: 500px) {
  .SectionContainer {
    overflow: hidden;
    border-radius: 20px 20px 0px 0px;
    margin: 0 auto;
    width: 90%;
    padding-bottom: 160px;
    height: calc(100vh);
  }
}
