$colors: (
  "c1": #28353c,
  "c2": #e26c82,
  "c3": #000000,
  "c4": #4dbd74,
  "c5": #ffffff,
  "c6": #0f1619,
  "c7": rgba(226, 108, 130, 0.1),
  "c8": #2f353a,
  "c9": #191b26,
  "c10": rgba(255, 255, 255, 0.247),
  "c11": #444444,
  "c12": #f6f7fa,
  "c13": #474a59,
  "c14": #20a8d8,
  "c15": rgba(255, 255, 255, 0.9),
  "c16": #dbf2e3,
  "c17": #f2dbdb,
  "c18": rgba(0, 0, 0, 0.1),
  "c19": #e30200,
  "c20": #46b335,
  "boxShadow": rgba(0, 0, 0, 0.25),
);
