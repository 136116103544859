@import "../../styles/variables";

.InductionContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 130px;
}
.InductionWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
}

.Heading {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: map-get($colors, c9);
  margin-bottom: 27px;
}

.RulesTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 3vh;
  line-height: 40px;
  color: map-get($colors, c3);
  margin: 20px;
}

.CheckboxContainer {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.CheckboxWrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.ProgressBar {
  display: flex;
  position: relative;
  width: 90%;
  height: 8px;
  border-radius: 3px;
  background-color: map-get($colors, c10);
  margin: 0px auto;
  z-index: 0;
  opacity: 0.24;
}
.Progress {
  display: flex;
  position: absolute;
  height: 8px;
  border-radius: 3px;
  background-color: map-get($colors, c5);
  z-index: 1;
}

@media (min-width: 1024px) {
  .InductionWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
