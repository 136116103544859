@import "../../../../styles/variables";

.CompletionText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 28px;

  line-height: 38px;
  letter-spacing: 2.78155e-9px;
  margin-top: -30px;
  color: map-get($colors, c5);
}
.CompletionTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 27px;
  text-align: center;
  letter-spacing: 2.78155e-9px;
  margin-top: 30px;
  color: map-get($colors, c5);
  text-align: center;
}
.CompletionSubText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  margin-top: 30px;
  color: map-get($colors, c5);
  text-align: center;
}
.CompletionContainer {
  width: 90%;
  height: 80vh;
  margin-top: 100px;
  overflow: hidden;
  background-color: map-get($colors, c14);
  border-radius: 20px;
  display: flex;
  justify-content: flex-start;
  margin: 0px 20px 20px 20px;
  align-items: center;
  flex-direction: column;
}

@media (max-width: 320px) {
  .CompletionText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;

    line-height: 38px;
    letter-spacing: 2.78155e-9px;
    margin-top: -55px;
    color: map-get($colors, c5);
  }
  .CompletionTitle {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    letter-spacing: 2.78155e-9px;
    margin-top: 10px;
    color: map-get($colors, c5);
    text-align: center;
  }
  .CompletionSubText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    margin-top: 10px;
    color: map-get($colors, c5);
    text-align: center;
  }
}

@media (max-width: 375px) {
  .CompletionText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    line-height: 38px;
    letter-spacing: 2.78155e-9px;
    margin-top: -30px;
    color: map-get($colors, c5);
  }
  .CompletionTitle {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    letter-spacing: 2.78155e-9px;
    margin-top: 30px;
    color: map-get($colors, c5);
    text-align: center;
  }
  .CompletionSubText {
    font-family: Open Sans;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    margin-top: 20px;
    color: map-get($colors, c5);
    text-align: center;
  }
}
