.section {
  width: 80%;
  align-self: center;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.tableHeaderContainer {
  position: relative;
  display: flex;
  overflow-x: auto;
}

.tableHead {
  width: 100%;
  position: relative !important;
  display: flex;
  overflow-x: auto;
}

.titleHead {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 138%;
  /* or 33px */

  display: flex;
  align-items: center;
  letter-spacing: 0.0075em;

  /* FRL Deep Ocean Grey */

  color: #303539;
  border-bottom: 1px solid rgba(165, 180, 201, 0.5);
  padding-bottom: 34px;
}

.tableHeaderWrapper {
  width: 100%;
  position: relative;
  flex-direction: row;
  display: flex;
}

.tableHeaderElement {
  vertical-align: middle;
  cursor: pointer;
  border-bottom: 2px solid;
  border-bottom-color: #d8dbe0;
  padding: 0.75rem;
  width: auto;
  justify-content: center;
  align-items: center;
  padding-right: 1.5rem !important;
  flex-direction: row;
}
.tableHeaderText {
  font-size: 15px;
}
.tableBody {
  td {
    vertical-align: middle !important;
    height: 81px !important;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: auto;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 15px;
  background-color: #fafafa;
  color: #1d1d1d;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  font-family: Open Sans;
  z-index: 1;
  top: 125%;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #fafafa transparent transparent transparent;
  transform: rotate(180deg);
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 1280px) {
  .tableHeaderText {
    font-size: 12px;
  }
}

.tableWrapper {
  width: 100%;
  margin: 27px auto;
  table {
    width: 100% !important;
  }
}
.paginationContainer {
  background: #ffffff;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.25);
  height: 35px;
  width: calc(100% - 256px);
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  span {
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgba(165, 180, 201, 0.2);
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
}

.backIcon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  left: 0px;
  top: 15px;
}

.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  text-align: center;
  display: flex;
  /* or 30px */

  letter-spacing: 0.0075em;

  /* 7185BE */

  color: #7185be;
}
.heading {
  position: relative;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.indicatorWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  justify-self: flex-end;
  .indicatorItem {
    margin-left: 18px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    div {
      display: flex;
      width: 10px;
      height: 10px;
      border-radius: 10px;
      margin-right: 8px;
    }
    span {
      display: flex;
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      letter-spacing: 0.0075em;
      color: #40507e;
    }
  }
}

.statusWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding-right: 50%;
  margin-bottom: 6px;
}
.status {
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: #bb6bd9;
}
.statusGray {
  display: flex;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: #dddddd;
}
.checkinStatus {
  color: #03ce91;
}
