@import "../../styles/variables";

.Heading {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 55px;
  /* or 196% */

  letter-spacing: 2.78155e-9px;

  color: map-get($colors, c3);
  margin: 18px;
}
.Form {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.ItemContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding-bottom: 200px;
}

.PopUpContainer {
  margin: 0 auto;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 90%;
  background: map-get($colors, c4);
  border: 1px solid map-get($colors, c4);
  box-sizing: border-box;
  box-shadow: 0px 12px 18px rgba(7, 125, 62, 0.05);
  border-radius: 18px;
  padding-top: 80px;
  margin-top: 80px;
}
.PopUpTextWrapper {
  width: 90%;
  margin-bottom: 50px;
}
.PopUpName {
  text-decoration: underline;
}
.PopUpText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 4vh;
  line-height: 38px;
  letter-spacing: 2.78155e-9px;

  color: map-get($colors, c5);
}
.PopUpTextFailure {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5vh;
  letter-spacing: 2.78155e-9px;

  color: map-get($colors, c5);
}

@media (max-width: 760px) {
  .Heading {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 3.8vh;
    line-height: unset;
    /* or 196% */

    letter-spacing: 2.78155e-9px;

    color: map-get($colors, c3);
    margin: 18px;
  }
  .PopUpContainer {
    margin-top: 30px;
  }
}
