.Container {
  width: 100vw;
  height: 100vh;
  background: rgb(255, 0, 70);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.iconWrapper {
  display: none;
}

.LogoWrapper {
  height: auto;
  width: 150px;
  justify-content: flex-start;
  display: flex;
  align-items: flex-end;
}
.Logo {
  justify-content: flex-start;
  display: flex;
  width: 90%;
}
.TextWrapper {
  margin: 47px;
}
.Text {
  font-family: "Open Sans";
  color: white;
  font-weight: 700;
  font-size: 30px;
}
.BottomContainer {
  position: absolute;
  bottom: 0;
  background-color: rgb(166, 0, 41);
  width: 100%;
  height: 220px;
}
.SoundContainer {
  width: 100%;
  height: 100px;
  background-color: rgb(233, 0, 42);
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.SoundLogo {
  margin: 0px 20px 30px 47px;
  width: 28px;
  height: 26px;
}

.SoundText {
  font-family: "Open Sans";
  font-weight: 400;
  font-size: 28px;
  color: white;
}

.SoundContainerLarge {
  display: none;
}
.SoundLogoLarge {
  display: none;
}

.SoundTextLarge {
  display: none;
}

.Skew {
  width: 107%;
  height: 100px;
  background-color: #e9002a;
  position: absolute;
  top: 36px;
  left: -2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: rotate(7deg);
}
.Skew1 {
  width: 107%;
  height: 100px;
  background-color: #e9002a;
  position: absolute;
  top: -29px;
  left: -2px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transform: rotate(-3deg);
}

@media (max-width: 375px) {
  .Logo {
    justify-content: flex-start;
    display: flex;
    width: 110%;
  }

  .Text {
    font-family: "Open Sans";
    color: white;
    font-weight: 700;
    font-size: 20px;
  }
}

@media (max-width: 320px) {
  .Logo {
    justify-content: flex-start;
    display: flex;
    width: 90%;
  }

  .Text {
    font-family: "Open Sans";
    color: white;
    font-weight: 700;
    font-size: 20px;
  }
  .BottomContainer {
    position: absolute;
    bottom: 0;
    background-color: rgb(166, 0, 41);
    width: 100%;
    height: 150px;
  }
  .SoundContainer {
    width: 100%;
    height: 50px;
    background-color: rgb(233, 0, 42);
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .SoundLogo {
    margin: 0px 20px 30px 47px;
    width: 28px;
    height: 26px;
  }

  .SoundText {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 28px;
    color: white;
  }

  .Skew {
    width: 107%;
    height: 50px;
    background-color: #e9002a;
    position: absolute;
    top: 36px;
    left: -2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: rotate(4deg);
  }
  .Skew1 {
    width: 107%;
    height: 76px;
    background-color: #e9002a;
    position: absolute;
    top: -34px;
    left: -2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: rotate(-7deg);
  }
  .SoundLogo {
    margin: 0px 20px 30px 47px;
    width: 28px;
    height: 26px;
  }

  .SoundText {
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 20px;
    color: white;
  }
}

@media (min-width: 1024px) {
  .iconWrapper {
    display: flex;
  }
  .SoundLogo {
    display: none;
  }
  .SoundText {
    display: none;
  }
  .Text {
    font-family: "Open Sans";
    color: white;
    font-weight: 700;
    font-size: calc(2.8vw + 2.2vh);
  }
  .TextWrapper {
    margin: 20px auto 0px auto;
  }
  .Logo {
    justify-content: flex-start;
    display: flex;
    width: 147%;
  }
  .Skew {
    width: 107%;
    height: 80px;
    background-color: #e9002a;
    position: absolute;
    top: 61px;
    left: -2px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transform: rotate(2deg);
  }
  .SoundContainerLarge {
    display: flex;
    height: 100px;
    background-color: transparent;
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-right: 30px;
  }
  .SoundLogoLarge {
    display: flex;
    margin-right: 10px;
    width: 28px;
    height: 26px;
  }

  .SoundTextLarge {
    display: flex;
    font-family: "Open Sans";
    font-weight: 400;
    font-size: 28px;
    color: white;
  }
  .Skew1 {
    display: none;
  }
  .BottomContainer {
    position: absolute;
    bottom: 0;
    background-color: rgb(166, 0, 41);
    width: 100%;
    height: 180px;
  }
}
