@import "../../../../../../styles/variables";

.QuestionTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  width: 90%;
  margin: 40px auto 20px auto;
  color: map-get($colors, c3);
  .Bold {
    font-weight: 700;
  }
}
.QuestionImage {
  border-radius: 20px;
  margin-bottom: 19px;
  display: block;
  min-width: 100%;
  max-height: 160px;
  width: 100%;
  height: auto;
}
.ChoiseContainer {
  min-height: 70px;
  width: 90%;
  background: map-get($colors, c5);
  box-sizing: border-box;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px auto;
  -webkit-box-shadow: 0px 2px 11px 0px map-get($colors, c18);
  box-shadow: 0px 2px 11px 0px map-get($colors, c18);
  position: relative;
}
.ChoiseTextWrapper {
  margin: 5%;
  width: 80%;
  display: flex;
  justify-content: flex-start;
}

.ChoiseText {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  color: map-get($colors, c8);
}
.SelectedCheckboxWrapper {
  margin: 2.5%;
  width: 10%;
  display: flex;
  justify-content: flex-start;
}
.ImageWrapper {
  flex: 1 1 0%;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  border-radius: 0px 0px 20px 20px;
  background-size: cover;
  background-position: center center;
}

.successContainer {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 100%;
  background-color: map-get($colors, c16);
  display: flex;
  align-items: center;
  span {
    color: map-get($colors, c20);
    font-weight: bold;
    margin-left: 10px;
    font-size: 18px;
    font-family: Open Sans;
  }
}
.errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  height: 50px;
  width: 100%;
  background-color: map-get($colors, c17);
  display: flex;
  align-items: center;
  span {
    color: map-get($colors, c19);
    margin-left: 10px;
    font-size: 18px;
    font-family: Open Sans;
  }
}

.statusIcon {
  position: absolute;
  top: 20%;
  right: -30px;
}

@media (min-width: 1024px) {
  .ImageWrapper {
    flex: 1 1 0%;
    display: flex;
    width: 100%;
    min-height: 200px;
    justify-content: center;
    border-radius: 0px 0px 20px 20px;
    background-size: cover;
    background-position: center center;
  }
}
