.qrWrapper {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  min-height: 70vh;
  background: white;
  margin: 0 auto;
  margin-bottom: 40px;
  padding-bottom: 20px;
}

.qrCodeWrapper
{
  width: 300px;
  margin: 0 auto;
}

.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  justify-self: center;
  margin: 0 auto;
  min-height: 80vh;
  width: 70vw;
}

.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.backIcon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin: 10px 10px 10px 0px;
}

.qrTitle
{
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  width: 100%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.qrHr
{
  margin-top: 40px;
}

.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 138%;
  /* or 30px */

  text-align: center;
  letter-spacing: 0.0075em;

  /* 7185BE */

  color: #7185be;
}

.qrCodeFooter
{
  width: 100%;
  clear: both;
  display: none;
}
.qrCodeFooterInner
{
   margin: 0 auto;
   margin-top: 100px;
   width: auto;
   max-width: 80%;
}

.keepsafeBar
{
  background-color: #fff;
  width: 100%;
  display: none;
}

.keepsafeBar img
{
  float: right;
  margin-right: 20px;
  margin-top: 20px;
}

.keepsafeBar a
{
  float: left;
  margin-left: 20px;
  margin-top: 20px;
  color: #000;
}

.qrCodeLogoBar
{
  display: none;
}

.qrBottomShadow
{
  display: none;
}

@media print
{

   .backIcon,
   .title
   {  
      display: none;
   }

   .qrHr
   {
     margin-top: 60px;
   }

   .qrWrapper
  {

  }

  .qrCodeLogoBar
  {
    text-align: left;
    width: 100%;
    display: block;
    background-color: #fff;
  }

  .qrCodeLogoBar img
  {
    max-width: 50%;
  }

  .qrTitle
  {
    color: #fff;
    font-weight: bold;
    font-size: 48px;
    padding-top: 60px;
  }

  .qrCodeWrapper
  {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
  }

   .container
   {
     display: block !important;
     flex-direction: unset !important;
   }

   .qrCodeCheckOut,
   .qrCodeSiteInduction
   {
     margin-top: 80vh;
     background-color: #FF0046;
     min-height: 80vh;
     position: relative;
   }

   .qrCodeCheckIn
   {
     background-color: #FF0046;
     min-height: 80vh;
     position: relative;
   }

   .qrCodeFooter
   {
     background-color: #FF0046;
     display:block;
     width: 100%;
     margin-top: 80px;
     text-align: center;
   }

   .qrCodeFooterInner
   {
     max-width: 65%;
     margin: 0 auto;
   }

   .qrCodeFooterInnerSiteInduction 
   {
      max-width: 80%;
   }

   .qrCodeFooter h2,
   .qrCodeFooter img
   {
     float: left;
   }

   .qrCodeFooter h2
   {
     color: #fff;
     margin-left: 20px;
     text-align: left;
     font-size: 24px;
     width: auto;
   }

   .keepsafeBar
   {
     display: block;
     position: absolute;
     bottom: 0;
     height: 50px;
   }

   .qrBottomShadow
   {
    display: block;
    position: absolute;
    bottom: 50px;
    width: 100%;  
   }

   .qrBottomShadow img
   {
     width: 100%;
   }

}



