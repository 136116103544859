@import "../../../../styles/variables";

.InductionContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 130px;
}

.Heading {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 38px;
  text-align: center;
  color: map-get($colors, c9);
  margin-bottom: 27px;
}
.SectionContainer {
  overflow: hidden;
  border-radius: 20px 20px 0px 0px;
  margin: 0px 20px;
  width: 90%;
  height: 100vh;
}
.SectionTitle {
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 35px;
  text-align: start;
  margin-top: 22px;
  margin-bottom: 18px;
  color: map-get($colors, c5);
}

.BackIconWrapper {
  display: flex;
  margin-right: 7px;
  margin-left: 20px;
}
.NextIconWrapper {
  display: flex;
  margin-left: 7px;
  margin-right: 20px;
}
.ProgressWrapper {
  display: flex;
  width: 90%;
  position: relative;
}
.ProgressBar {
  display: flex;
  position: relative;
  width: 90%;
  height: 8px;
  border-radius: 3px;
  background-color: map-get($colors, c10);
  margin: 0px auto;
  z-index: 0;
  opacity: 0.24;
}
.Progress {
  display: flex;
  position: absolute;
  left: 0px;
  height: 8px;
  border-radius: 3px;
  background-color: map-get($colors, c5);
  z-index: 1;
}
.FooterProgressContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 123px;
}
