.tableWrapper {
  width: 70vw;
  margin: 0 auto;
  table {
    width: 100% !important;
  }
}
.paginationContainer {
  background: #ffffff;
  box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.25);
  height: 35px;
  width: calc(100% - 256px);
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  span {
    cursor: pointer;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: rgba(165, 180, 201, 0.2);
    }
  }
}
.darkButton {
  width: 121px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: #40507e;
  border: 1px solid #40507e;
  border-radius: 6px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 138%;
  letter-spacing: 0.0075em;
  color: #ffffff;
  &:hover {
    background: #ffffff;
    color: #40507e;
  }
  &:focus {
    outline: none;
  }
}

.container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0 auto;
}

.backIcon {
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
}

.title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  text-align: center;
  display: flex;
  /* or 30px */

  letter-spacing: 0.0075em;

  /* 7185BE */

  color: #7185be;
}
.heading {
  position: relative;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 70vw;
}

.statusWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: flex-start;
  padding-right: 50%;
  margin-bottom: 6px;
  .status {
    margin-right: 20px;
    width: 8px;
    height: 8px;
    background-color: gray;
    border-radius: 8px;
  }
}
