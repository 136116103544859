.Icon {
  width: 47px;
  height: 47px;
  background: #4dbd74;
  border-radius: 22.5px;
  border: 3px solid #ffffff;
  box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.0615986),
    0px 0px 20px rgba(82, 87, 84, 0.1);
  margin: "5px";
  cursor: pointer;
}

.Image {
  width: 50%;
  height: 50%;
  display: flex;
  margin: auto;
  margin: 25%;
  justify-content: center;
}
