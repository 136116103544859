@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

@mixin animation($args...) {
  -webkit-animation: $args;
  -moz-animation: $args;
  -ms-animation: $args;
  -o-animation: $args;
  animation: $args;
}

.DrawerContainer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  background-color: #73818f;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  z-index: 999;
}

.CloseIcon {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50px;
  left: -26px;
  animation: rotate-icon 0.9s linear; /* IE 10+, Fx 29+ */
}
.Wrapper {
  margin: 3vh 7vw 3vh 10vw;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
}
.Heading {
  font-weight: 700;
  color: white;
  font-size: 5.5vh;
  font-family: "Open Sans";
  margin-bottom: 0.2vh;
  cursor: pointer;
}
.Title {
  color: white;
  font-size: 2.9vh;
  font-family: "Open Sans";
  font-weight: 700;
  margin-bottom: 1vh;
  cursor: pointer;
  &:hover {
    color: #c5c7c9;
    text-decoration: none;
  }
  &:focus {
    color: #9da0a3;
  }
}
.RulesContainer {
  display: flex;
  width: 65%;
  flex-direction: column;
  margin: 0px 20px 0px 19px;
}
.DisabledTitle {
  color: rgb(179, 187, 194);
  font-size: 2.9vh;
  font-family: "Open Sans";
  font-weight: 700;
  cursor: not-allowed;
  margin-bottom: 1vh;
  &:hover {
    text-decoration: none;
  }
}
.ActiveSubTitle {
  color: white;
  font-size: 2.6vh;
  font-family: "Open Sans";
  margin-bottom: 10px;
  cursor: pointer;
  &:hover {
    color: #c5c7c9;
    text-decoration: none;
  }
  &:focus {
    color: #9da0a3;
  }
}
.DisabledSubTitle {
  color: rgb(179, 187, 194);
  font-size: 2.6vh;
  font-family: "Open Sans";
  margin-bottom: 10px;
  cursor: not-allowed;
}

@-webkit-keyframes rotate-icon {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-moz-keyframes rotate-icon {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-o-keyframes rotate-icon {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotate-icon {
  0% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
